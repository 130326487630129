import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Flex/Flex.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Group/Group.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Input"] */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Input/Input.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Space"] */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Space/Space.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Stack/Stack.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Text/Text.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["UnstyledButton"] */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/UnstyledButton/UnstyledButton.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/bot.png");
;
import(/* webpackMode: "eager" */ "/app/public/line_branding.png");
;
import(/* webpackMode: "eager" */ "/app/public/partcipant.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AdminImageMessageBubble/AdminImageMessageBubble.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AdminVideoMessageBubble/AdminVideoMessageBubble.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BotCard/BotCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BotProfileTab/BotProfileTab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Chat/Chat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/EnabledText/EnabledText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LoaderCentered/LoaderCentered.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MagicBorder/MagicBorder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NavigationBar/NavigationBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ParticipantImageMessageBubble/ParticipantImageMessageBubble.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ParticipantVideoMessageBubble/ParticipantVideoMessageBubble.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/RouterTransition/RouterTransition.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Sidebar/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Tab/Tab.tsx");
